
import { defineComponent } from 'vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import moment from 'moment';
import MapTrainerInstitute from '@/layout/header/partials/trainer/mapTrainerInstituteDrawer.vue';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainer-search',
  components: {
    Datatable,
    MapTrainerInstitute,
  },
  data() {
    return {
      api_url: '',
      trainer: {
        registration_number: '',
        nid: '',
        bcn: '',
        mobile: '',
      },
      trainerInfo: {},
      educationalDetails: [] as any,
      professionalDetails: [] as any,
      batchDetails: [] as any,
      entity_type_id: '',
      adminPermission: false,
      userInfo: {
        employee: {
          entity_organogram: [],
        } as any,
      },
      load: false,
      moment: '' as any,
      componentKey: 0,
      showTrainerInfo: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.moment = moment;
    this.userInfo = VueCookieNext.getCookie('_seip_user');
    let userRole =
      this.userInfo?.employee?.entity_organogram[0]?.entitytyperole?.role_title;
    if (userRole == 'Admin') {
      this.adminPermission = true;
    }
    this.entity_type_id = VueCookieNext.getCookie('_seip_entity_type');
  },
  methods: {
    async getTrainerInfo() {
      this.load = true;
      if (
        this.trainer.registration_number ||
        this.trainer.nid ||
        this.trainer.bcn ||
        this.trainer.mobile
      ) {
        await ApiService.get(
          'entity/trainer/search-trainer?unique_number=' +
            this.trainer.registration_number +
            '&nid=' +
            this.trainer.nid +
            '&bcn=' +
            this.trainer.bcn +
            '&mobile=' +
            this.trainer.mobile
        )
          .then((response) => {
            console.log(response);
            this.load = false;
            if (!response.data.data.trainer) {
              this.showTrainerInfo = false;
              Swal.fire({
                title: 'Trainer Finding',
                text: 'No Match',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            }
            this.trainerInfo = response.data.data.trainer;
            // this.educationalDetails = JSON.parse(
            //   response.data.data.educational_details
            // );
            // this.professionalDetails = JSON.parse(
            //   response.data.data.professional_details
            // );
            this.trainerInfo = response.data.data['trainer'];
            this.educationalDetails =
              response.data.data['trainer'].educational_details;
            this.professionalDetails =
              response.data.data['trainer'].professional_details;
            this.batchDetails = response.data.data['batchTrack'];
            // console.log(this.batchDetails);
            this.showTrainerInfo = true;
          })
          .catch(({ response }) => {
            this.load = false;
            console.log(response);
          });
      } else {
        this.load = false;
        Swal.fire({
          title: 'Please Provide NID or BCN or Trainer Number or Mobile Number',
          text: 'Empty Parameter',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    MapTrainerInstitute(id) {
      this.emitter.emit('entity-add-maptrainerInstitute-data', id);
    },
  },
});
